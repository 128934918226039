

































































































































































































import moment from 'moment';
import { mapGetters } from 'vuex';
import { DataTable, Form, ReportForm } from '@/components/admin';
import Vue from 'vue';
import { Component, Mixins } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import NotificationMixin from '@/mixins/NotificationMixin';
import ReportMixin from '@/mixins/ReportMixin';

@Component({
  name: 'Reports',
  components: {
    DataTable,
    Form,
    ReportForm,
  },
  computed: {
    ...mapGetters('report', ['getList', 'getCount']),
  },
})
export default class Reports extends Mixins(NotificationMixin, ReportMixin) {
  tooltipOptions = {
    placement: 'bottom',
    template: `
      <div class="table-header-tooltip tooltip" role="tooltip"> <div class="arrow"></div> <div class="tooltip-inner"></div> </div>
    `,
  };

  isUploading = false;
  disabledUpload = false;
  fileList = [];
  selected: any = {};
  fields = [
    {
      key: 'client.name',
      label: 'Client',
      sortable: true,
      thClass: 'width-140',
    },
    {
      key: 'teamName',
      sortable: true,
      thClass: 'width-95',
    },
    {
      key: 'template.name',
      label: 'Report Type',
      sortable: true,
      thClass: 'report-type',
    },
    {
      key: 'createdAt',
      label: 'Created',
      sortable: true,
      formatter(val) {
        return val ? moment(val).format(process.env.VUE_APP_DATE_FORMAT) : null;
      },
    },
    {
      key: 'reportStatus',
      sortable: true,
    },
    {
      key: 'reportAssigned',
      sortable: true,
    },
  ];

  get reportUploadUrl() {
    if (this.selected && this.selected.id) {
      const baseUploadUrl = process.env.VUE_APP_API_BASEURL || 'http://localhost:8080/v1';
      const uploadPath = `/reports/${this.selected.id}/upload`;
      return `${baseUploadUrl}${uploadPath}`;
    }
    return '';
  }

  addReport() {
    this.selected = {};
    this.$refs.addModal.show();
  }

  deleteReport(report) {
    this.selected = report;
    this.$refs.deleteModal.show();
  }

  uploadReport(report) {
    this.selected = report;
    this.$refs.uploadModal.show();
  }

  onCancel() {
    this.selected = { clients: [] };
    this.$refs.addModal.hide();
  }

  onModalHidden() {
    this.$refs.uploadForm.clearFiles();
  }

  onSwitchChange(report) {
    const { id } = report;
    const data = {
      reportStatus: report.reportStatus || false,
      reportAssigned: report.reportAssigned || false,
    };
    return this.$store
      .dispatch('report/update', { id, data })
      .then(() => {
        this.$refs.table.refresh();
      })
      .catch((err) => {
        const errMessage = err.message || 'Failed to update report';
        this.notifyError(errMessage);
        this.$refs.table.refresh();
      });
  }

  onProgressUpload() {
    this.isUploading = true;
    this.disabledUpload = true;
  }

  onSuccessUpload() {
    this.isUploading = false;
    this.notifySuccess('Report uploaded.');
    this.disabledUpload = false;
    this.$refs.uploadModal.hide();
  }

  onErrorUpload() {
    this.isUploading = false;
    this.notifyError('Failed to upload report.');
    this.disabledUpload = false;
    this.$refs.uploadModal.hide();
  }

  cancelUpload() {
    if (!this.isUploading) {
      this.$refs.uploadModal.hide();
    }
  }
}
