import mime from 'mime-types';
import moment from 'moment';
import { BModal } from 'bootstrap-vue';
import { Component, Mixins } from 'vue-property-decorator';
import NotificationMixin from './NotificationMixin';
import { DataTable } from '../components/admin';
import { ElUpload } from 'element-ui/types/upload';

@Component({
  components: {
    DataTable
  }
})
export default class ReportMixin extends Mixins(NotificationMixin) {
    selected: any = null;
    readonly $refs!: {
        addModal: BModal;
        editModal: BModal;
        resetModal: BModal;
        deleteModal: BModal;
        uploadModal: BModal;
        table: DataTable;
        uploadForm: ElUpload
      };

    fetchData (params: Record<string, string | boolean> = {}) {
      params.sort = params.sort || '-createdAt';
      this.$store.dispatch('report/list', { params });
      this.$store.dispatch('report/count', { params });
    }

    handleAdd (data) {
      return this.$store.dispatch('report/create', { data }).then(() => {
        this.$refs.addModal.hide();
        this.$refs.table.refresh();
      }).catch((err) => {
        const errMessage = err.message || 'Failed to create report';
        this.notifyError(errMessage);
      });
    }

    handleDelete (evt) {
      const { id } = this.selected;
      return this.$store.dispatch('report/delete', { id }).then(() => {
        this.$refs.deleteModal.hide();
        this.$refs.table.refresh();
      }).catch((err) => {
        const errMessage = err.message || 'Failed to delete report';
        this.notifyError(errMessage);
      });
    }

    previewReport (report, isPdf = false) {
      if (!isPdf && (!report.datasource || report.datasource.length === 0)) {
        return;
      }
      if (isPdf && !report.reportUrl) {
        return;
      }
      this.notifyInfo('Downloading report...');
      const id = report.id;
      // const params = {
      //  word: 1
      // }
      const params: Record<string, number> = {};
      if (isPdf) {
        params.pdf = 1;
      } else {
        params.word = 1;
      }
      return this.$store.dispatch('report/download', { id, params }).then((res) => {
        const ext = mime.extension(res.type);
        const createdAt = moment(report.createdAt).format('MMM YY');
        const fileName = `${report.client.name}_${report.teamName}_${report.template.name} Report (${createdAt}).${ext}`;

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(res, fileName);
        } else {
          // For other browsers, create a link pointing to the ObjectURL containing the blob.
          const objectURL = window.URL.createObjectURL(res);
          const link = document.createElement('a');
          link.href = objectURL;
          link.download = fileName;
          document.body.appendChild(link); //  Need to do this for Firefox
          link.click();

          setTimeout(function () {
            document.body.removeChild(link); //  Need to do this for Firefox
            window.URL.revokeObjectURL(objectURL);
          }, 100);
        }

        this.notifySuccess('Report downloaded');
      }).catch((err) => {
        const errMessage = err.message || 'Failed to download report';
        this.notifyError(errMessage);
      });
    }

    downloadResponses (report) {
      if (!report.datasource || report.datasource.length === 0) {
        return;
      }
      const id = report.id;
      const params = {
        dl: 1
      };
      this.notifyInfo('Downloading responses...');
      return this.$store.dispatch('report/getResponses', { id, params }).then((res) => {
        const ext = mime.extension(res.type);
        const createdAt = moment(report.createdAt).format('MMM YY');
        const fileName = `${report.client.name}_${report.teamName}_${report.template.name}_Raw Data (${createdAt}).${ext}`;

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(res, fileName);
        } else {
          // For other browsers, create a link pointing to the ObjectURL containing the blob.
          const objectURL = window.URL.createObjectURL(res);
          const link = document.createElement('a');
          link.href = objectURL;
          link.download = fileName;
          document.body.appendChild(link); //  Need to do this for Firefox
          link.click();

          setTimeout(function () {
            document.body.removeChild(link); //  Need to do this for Firefox
            window.URL.revokeObjectURL(objectURL);
          }, 100);
        }

        this.notifySuccess('Responses downloaded');
      }).catch((err) => {
        const errMessage = err.message || 'Failed to download responses';
        this.notifyError(errMessage);
      });
    }
}
